export const companySectors = [
    {
        value: 1,
        text: 'Postal and telecommunications services'
    },
    {
        value: 2,
        text: 'Water and Energy'
    },
    {
        value: 3,
        text: 'Consumer Goods'
    },
    {
        value: 4,
        text: 'Travel Services'
    },
    {
        value: 5,
        text: 'Recreational Services'
    },
    {
        value: 6,
        text: 'Financial Services'
    },
    {
        value: 7,
        text: 'Consumer Services'
    },
    {
        value: 8,
        text: 'Health'
    },
    {
        value: 9,
        text: 'Education'
    },
    {
        value: 10,
        text: 'Other Services'
    },
];
import { Block, DeleteOutline } from "@mui/icons-material";
import {
    Collapse,
    TableCell,
    TableRow,
  } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userStatuses } from "../../../app/config/userStatuses";
import {LAYOUT_CONFIG} from "../../../layoutConfig";


function UsersTableRow({ open, user, onRowClick, onStatusChange, onDelete, isCurrentUserAdmin = false}) {
    const { t: t_button } = useTranslation('Buttons');
    const { t: t_link} = useTranslation('Pages');
    const { t: t_status } = useTranslation('UsersScreen', { keyPrefix: 'TableFilter.status' });

    const navigate = useNavigate();
    const statusText = _.find(
        userStatuses,
        (e) => e.value === user.isBlocked
      )?.text;

    return (
        <>
            <TableRow
                className={`row ${open ? " active" : ""}`}
            >
                <TableCell
                    padding="none"
                    className="fk-table-cell --first"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">{user.id}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">{user.fullName}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">{user.email}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">{user.roles}</span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">
                        {format(
                        new Date(user.createdAt),
                        "yyyy-MM-dd 'godz:' HH:mm"
                        )}
                    </span>
                </TableCell>
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={() => onRowClick(user.id)}
                >
                    <span className="value">{t_status(statusText)}</span>
                </TableCell>
                <TableCell padding="none" className="fk-table-cell crud --last">
                    <span className="value">
                        {/*<Block onClick={() => onStatusChange(user.id)} />*/}
                        {isCurrentUserAdmin &&
                        <DeleteOutline
                            onClick={() => onDelete(user.id)}
                        />
                        }
                        <button
                            className="show-details-button"
                            onClick={() => navigate(t_link('userLink').replace(':id', user.id))}
                        >
                            {t_button('show')}
                        </button>
                    </span>
                </TableCell>
            </TableRow>
            {open &&
                <TableRow className="row collapsed-data">
                    <TableCell
                        className="fk-table-cell --first"
                        colSpan={7}
                    >
                        <Collapse in={open} timeout="auto" className="collapse">
                            {user.fullName}
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

      </>
    );
}

export default connect(null, {})(UsersTableRow);
export const complainStatuses = [
    {
        value: 0,
        sec: 'Open',
        text: 'open',
        disabled: false,
    },
    {
        value: 3,
        sec: 'Draft',
        text: 'draft',
        disabled: true,
    },
    {
        value: 4,
        sec: 'Closed Resolved',
        text: 'closed_resolved',
        disabled: false,
    },
    {
        value: 5,
        sec: 'Closed Unresolved',
        text: 'closed_unresolved',
        disabled: false,
    },
    {
        value: 7,
        sec: 'Closed Automatically',
        text: 'closed_automatically',
        disabled: true,
    },
    {
        value: 6,
        sec: 'Undelivered',
        text: 'undeliveredEmailStatusText',
        disabled: false,
    },
];

export const complainMinStatuses = [
    {
        value: 0,
        sec: 'Open',
        text: 'open',
        disabled: false,
    },
    {
        value: 3,
        sec: 'Draft',
        text: 'draft',
        disabled: true,
    },
    {
        value: 4,
        sec: 'Closed Resolved',
        text: 'closed_resolved',
        disabled: false,
    },
    {
        value: 5,
        sec: 'Closed Unresolved',
        text: 'closed_unresolved',
        disabled: false,
    },
    {
        value: 7,
        sec: 'Closed Automatically',
        text: 'closed_automatically',
        disabled: true,
    },
];